import { Templet1 } from "./templates/templet1";

function Page() {
  return (
    <>
      <Templet1
        headline="Om Bredbandsforum"
        topParagraph="Bredbandsforum är en viktig del av regeringens arbete med att uppfylla de nationella bredbandsmålen."
        images="/media/1065/1858727-telecom-tower.jpg"
        textbody={[
          <h3>Samverkan för bredband i världsklass</h3>,
          <p>
            I november 2009 antog riksdagen en nationell bredbandsstrategi, som
            betonade vikten av samverkan och samarbete. Året därpå tillsattes
            regeringens Bredbandsforum. Forumet främjar samverkan kring
            bredbandsutbyggnad. Tillsammans med företag, myndigheter och
            intresseorganisationer hittar vi lösningar för att alla ska få
            tillgång till snabbt och tillförlitligt bredband. Den nuvarande
            nationella bredbandsstrategin,{" "}
            <a href="https://bredbandsforum.se/media/1377/sverige-helt-uppkopplat-2025-slutlig.pdf" target="_blank">
              Sverige helt uppkopplat 2025 – en bredbandsstrategi
            </a>
            , antogs 2016 och Bredbandsforum är fortsatt en viktig del av
            strategin.
          </p>,
          <p>
            Bredbandsforum leds av en{" "}
            <a
              data-udi="umb://document/0ed20c41008c46cb8b437dca2ad783fa"
              href="/om-bredbandsforum/bredbandsforums-styrgrupp/"
              title="Bredbandsforums styrgrupp"
            >
              styrgrupp
            </a>{" "}
            där statsrådet med ansvar för digitaliseringsfrågor är ordförande.
          </p>,
          <p>
            Relevanta frågor och områden behandlas i tidsbegränsade{" "}
            <a
              data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
              href="/faerdplan-foer-maaluppfyllnad-2025/"
              title="Pågående arbetsgrupper"
            >
              arbetsgrupper
            </a>
            .
          </p>,
          <p>
            Bredbandsforums löpande verksamhet drivs av ett{" "}
            <a
              data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
              href="/om-bredbandsforum/bredbandsforums-kansli/"
              title="Bredbandsforums kansli"
            >
              kansli
            </a>
            .
          </p>,
        ]}
        cardtitle="Mer om Bredbandsforum"
        carddata={[
          {
            textbody: (
              <div>
                <p>
                  <a
                    data-udi="umb://document/0ed20c41008c46cb8b437dca2ad783fa"
                    href="/om-bredbandsforum/bredbandsforums-styrgrupp/"
                    title="Bredbandsforums styrgrupp"
                  >
                    Bredbandsforums styrgrupp
                  </a>
                </p>
                <p>
                  <a
                    data-udi="umb://document/0ed20c41008c46cb8b437dca2ad783fa"
                    href="/om-bredbandsforum/bredbandsforums-styrgrupp/"
                    title="Bredbandsforums styrgrupp"
                  ></a>
                  <a
                    data-udi="umb://document/c4deb14b8bd74601a6da2cbd5fec3dd1"
                    href="/om-bredbandsforum/material-fraan-styrgruppen/"
                    title="Material från styrgruppen"
                  >
                    Material från styrgruppen
                  </a>
                </p>
                <p>
                  <a
                    data-udi="umb://document/c4deb14b8bd74601a6da2cbd5fec3dd1"
                    href="/om-bredbandsforum/material-fraan-styrgruppen/"
                    title="Material från styrgruppen"
                  ></a>
                  <a
                    data-udi="umb://document/91d5757983b447d29e4584e192c0256a"
                    href="/faerdplan-foer-maaluppfyllnad-2025/"
                    title="Pågående arbetsgrupper"
                  >
                    Bredbandsforums arbetsgrupper
                  </a>
                </p>
                <p>
                  <a
                    data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
                    href="/om-bredbandsforum/bredbandsforums-kansli/"
                    title="Bredbandsforums kansli"
                  >
                    Bredbandsforums kansli
                  </a>
                  
                </p>
                <p>
                  <a
                    data-udi="umb://document/09ce92ab70d74c4499fa705593176d4f"
                    href="/om-bredbandsforum/bredbandsforums-kansli/"
                    title="Bredbandsforums kansli"
                  ></a>
                  <a
                    data-udi="umb://document/23ba939607e1464ab5a68a5806faba20"
                    href="/om-bredbandsforum/bredbandsforums-nyhetsbrev/"
                    title="Bredbandsforums nyhetsbrev"
                  >
                    Bredbandsforums nyhetsbrev
                  </a>
                 
                </p>
                <p>
                  <a
                    data-udi="umb://document/23ba939607e1464ab5a68a5806faba20"
                    href="/om-bredbandsforum/bredbandsforums-nyhetsbrev/"
                    title="Bredbandsforums nyhetsbrev"
                  ></a>
                  <a
                    data-udi="umb://document/2a0def31b68b4879941f474b87d04e01"
                    href="/om-bredbandsforum/riktlinjer-foer-personuppgiftshantering/"
                    title="Riktlinjer för personuppgiftshantering"
                  >
                    Riktlinjer för personuppgiftshantering
                  </a>
                </p>
              </div>
            ),
          },
        ]}
      />
    </>
  );
}
export default Page;
